import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, TopCard, ProgressBar, ScoreCardBar, ScoreCard, WellnessWinsCard/*, CircleNew*/ } from './styles'
import { isUndefined, isEmpty } from 'lodash';
import Waiting from '../../Waiting';
// import CircularProgressBar from './circularProgressBar';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
import { ToolTipNew } from "../../AdminTeamCreation/styles";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Border } from '../../EventDetails/styles';
import { commaSeperator } from '../../../utils/methods';
import {InfoIconV2} from '../../../utils/icons';

class HealthTab extends Component {

  renderFourCards = () => {
    const { healthWellbeingCardData } = this.props;
    // window.console.log("healthwellbeing data",healthWellbeingCardData);
    const topCardssData = [
      {
        name: 'Health & Wellness Assessment',
        background_color: healthWellbeingCardData['health_wellness_assessment']['background_color'],
        graph_color: healthWellbeingCardData['health_wellness_assessment']['graph_color'],
        completed_user: healthWellbeingCardData['health_wellness_assessment']['having_wellness_users'],
        total_company_users: `${!isEmpty(healthWellbeingCardData['health_wellness_assessment'])}` ? `${healthWellbeingCardData['health_wellness_assessment']['total_company_users']}` : 0,
        text: `completed out of`,
        icon: '/images/AdminReports/assesment.png',
        shadowColor: "rgba(118, 171, 120, 0.1)"
      },
      {
        name: 'Overall Company Wellbeing Score',
        background_color: `${!isEmpty(healthWellbeingCardData['company_wellbeing_score'])}` ? `${healthWellbeingCardData['company_wellbeing_score']['background_color']}` : 0,
        percentage: `${!isEmpty(healthWellbeingCardData['company_wellbeing_score'])}` ? `${healthWellbeingCardData['company_wellbeing_score']['over_all_company_wellbeing_percentage']}` : 0,
        text: "Company Health",
        graph_color: `${!isEmpty(healthWellbeingCardData['company_wellbeing_score'])}` ? "#F4AAA9":0,
        icon: '/images/AdminReports/health.png',
        shadowColor: "rgba(244, 170, 169, 0.15)"
      },
      {
        name: 'Workplace Wellbeing Score',
        background_color: `${!isEmpty(healthWellbeingCardData['workplace_wellbeing_score'])}` ? `${healthWellbeingCardData['workplace_wellbeing_score']['background_color']}` : 0,
        graph_color: `${!isEmpty(healthWellbeingCardData['workplace_wellbeing_score'])}` ? `${healthWellbeingCardData['workplace_wellbeing_score']['graph_color']}` : 0,
        percentage: `${!isEmpty(healthWellbeingCardData['workplace_wellbeing_score'])}` ? `${healthWellbeingCardData['workplace_wellbeing_score']['over_all_workplace_percentage']}` : 0,
        text: "Overall Score", // "Overall Workplace Wellbeing",
        icon: '/images/AdminReports/wellbeing.png',
        shadowColor: "rgba(241, 201, 119, 0.1)"
      },
      {
        name: 'Employee Wellness Score',
        background_color: `${!isEmpty(healthWellbeingCardData['employee_wellness_score'])}` ? `${healthWellbeingCardData['employee_wellness_score']['background_color']}` : 0,
        graph_color: `${!isEmpty(healthWellbeingCardData['employee_wellness_score'])}` ? `${healthWellbeingCardData['employee_wellness_score']['graph_color']}` : 0,
        percentage: `${!isEmpty(healthWellbeingCardData['employee_wellness_score'])}` ? `${healthWellbeingCardData['employee_wellness_score']['employee_wellness_percentage']}` : 0,
        text: "Overall Score", // "Overall Employee Wellness",
        icon: '/images/AdminReports/score.png',
        shadowColor: "rgba(133, 192, 234, 0.1)"
      }
    ]
    return (
      topCardssData.map((data, index) => (
        index === 1 ?
          <TopCard iconWidth="48px"  width="288px" height="180px" marginBottom="25px" key={index} color={data.graph_color} shadowColor={data.shadowColor} paddingTop="25px">
            <div className="heading">
              <LazyImage div={"icon"} src={ImageUrl + data.icon} alt={"arrow-up"} style={{width: "24px", height: "24px", flexShrink: "0"}} />
              <div className="name" style={{ paddingRight:'18px' }}>{data.name}</div>
            </div>
            <div className="body">
              <div className="points" style={{ display: 'flex', alignItems: "center" }}>{`${data.percentage}%`}
                <div className='taken'>{"Company Health"}</div>
              </div>
              <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'10px 0px 12px 0px', padding: '0px'}}/>
              <div className="progressBar">
                <ProgressBar height="20px"  backgroundActive={data.graph_color} width={data.percentage}>
                  <div className="progress"></div>
                </ProgressBar>
              </div>
            </div>
          </TopCard>:
          <TopCard iconWidth="48px" width="288px" height="180px" marginBottom="25px" key={index} color={data.graph_color} paddingTop={index===0?"25px":"25px"} shadowColor={data.shadowColor} >
            <div className="heading">
              <LazyImage div={"icon"} src={ImageUrl + data.icon} alt={"arrow-up"} style={{width: "24px", height: "24px", flexShrink: "0" }} />
              <div className="name" style={{ paddingRight:'18px' }}>{data.name}</div>
            </div>
            <div className="body" style={{ height: 'unset' }}>
              <div className="points" style={{ display: 'flex', alignItems: "center" }}>{index === 0 ? `${Math.round((data.completed_user*100)/data.total_company_users)}%` : `${data.percentage}%`}
                <div className='taken'>{index === 0?"Assessment Taken":data.text}</div>
              </div>
              <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'10px 0px 12px 0px', padding: '0px'}}/>
              <div className="subText" style={{display: index===0 ? 'inline' : 'none' }}><span>{index===0 && data.completed_user} users&nbsp;</span> {index===0&&<span className="subContentText">{data.text}</span>}&nbsp;<span className="subContentText">{data.total_company_users}</span></div>
            </div>
            {index !== 0 && <div className="progressBar">
              <ProgressBar height="20px"  backgroundActive={data.graph_color} width={index===0?(data.completed_user*100)/data.total_company_users:data.percentage}>
                <div className="progress"></div>
              </ProgressBar>
            </div>}
          </TopCard>
      ))
    )
  }

  healthWellnessScoreCard = () => {
    const { assesmentDetails } = this.props;
    const healthWellnessScore = [
      {
        name: 'Nutrition',
        percentage: assesmentDetails ? assesmentDetails.nutrition : 0,
        earned: assesmentDetails ? assesmentDetails.nutrition_points : 0,
        total: assesmentDetails ? assesmentDetails.nutrition_total_points : 0,
        background: 'rgba(118, 171, 120, 1)',
        color: 'rgba(0, 92, 135, 0.05)',
        icon: '/images/AdminReports/Nutrition.png',
        bgColor: "rgba(118, 171, 120, 0.10)",
      },
      {
        name: 'Physical Activity',
        percentage: assesmentDetails ? assesmentDetails.fitness : 0,
        earned: assesmentDetails ? assesmentDetails.fitness_points : 0,
        total: assesmentDetails ? assesmentDetails.fitness_total_points : 0,
        background: 'rgba(133, 192, 234, 1)',
        color: 'rgba(0, 92, 135, 0.05)',
        icon: '/images/AdminReports/fitness.png',
        bgColor: "rgba(133, 192, 234, 0.10)",
      },
      {
        name: 'Workplace Wellness',
        percentage: assesmentDetails ? assesmentDetails.workplace : 0,
        earned: assesmentDetails ? assesmentDetails.workplace_points : 0,
        total: assesmentDetails ? assesmentDetails.workplace_total_points : 0,
        background: 'rgba(241, 201, 119, 1)',
        color: 'rgba(0, 92, 135, 0.05)',
        icon: '/images/AdminReports/workplace.png',
        bgColor: "rgba(241, 201, 119, 0.10)",
      },
      {
        name: 'Lifestyle',
        percentage: assesmentDetails ? assesmentDetails.lifestyle : 0,
        earned: assesmentDetails ? assesmentDetails.lifestyle_points : 0,
        total: assesmentDetails ? assesmentDetails.lifestyle_total_points : 0,
        background: 'rgba(175, 135, 193, 1)',
        color: 'rgba(0, 92, 135, 0.05)',
        icon: '/images/AdminReports/lifestyle.png',
        bgColor: "rgba(175, 135, 193, 0.10)",
      },
      {
        name: 'Emotional Wellness',
        percentage: assesmentDetails ? assesmentDetails.stress : 0,
        earned: assesmentDetails ? assesmentDetails.stress_points : 0,
        total: assesmentDetails ? assesmentDetails.stress_total_points : 0,
        background: '#FFBF73',
        color: 'rgba(0, 92, 135, 0.05)',
        icon: '/images/AdminReports/emotional_wellness.png',
        bgColor: "rgba(255, 191, 115, 0.10)",
      }
    ]

    return (
      healthWellnessScore.map((data, index) => (
        <ScoreCardBar key={index} style={{ gap: '8px', marginBottom: '15px' }}>
          {/* <div style={{backgroundColor: data.bgColor && data.bgColor, height: "50px", width: "50px", borderRadius: "6px" }}>
            <LazyImage div={'image'} src={ImageUrl + data.icon} alt={"arrow-up"} style={{ height: "30px", width: "30px", flexShrink: "0", display: "flex", justifyContent: "center", alignItems: "center", margin: "10px" }} />
          </div> */}
          <div className="wrapper">
            <div className="score">
              <div className="title">{data.name}</div>
              <div className="points">{commaSeperator(data.total)} Points</div>
            </div>
            <ProgressBar radius height="28px" width={(data.earned*100)/data.total} backgroundActive={data.background} background={data.color}>
              <div className="progress" style={{ height: '28px', borderRadius: '0px 4px 4px 0px' }}></div><span className='percentage-value'>{data.earned}</span>
            </ProgressBar>
          </div>
        </ScoreCardBar>))
    )
  }

  infoIcon = () =>(
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 0C4.91333 0 0 4.91333 0 11C0 17.0867 4.91333 22 11 22C17.0867 22 22 17.0867 22 11C22 4.91333 17.0867 0 11 0ZM12.2222 16.1089C12.2222 16.7689 11.66 17.3067 11 17.3067C10.34 17.3067 9.77778 16.7689 9.77778 16.1089V9.85111C9.77778 9.19111 10.34 8.65333 11 8.65333C11.66 8.65333 12.2222 9.19111 12.2222 9.85111V16.1089ZM11.0244 7.08889C10.3644 7.08889 9.82667 6.55111 9.82667 5.89111C9.82667 5.23111 10.3644 4.69333 11.0244 4.69333C11.6844 4.69333 12.2222 5.23111 12.2222 5.89111C12.2222 6.55111 11.6844 7.08889 11.0244 7.08889Z" fill="white"/>
    </svg>
  )

  infoDetails = (text) =>{
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "10px 0px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
          marginTop:"7px"
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );
    return(
      <ToolTipNew
        margin={"auto 0px auto auto"}
        width="24px"
        height="24px"
        transparent={1}
      >
        <OverlayTrigger
          placement="bottom"
          overlay={popover(text)}
        >
          <div
            style={{width:"24px", maxHeight:"27px"}}
          >
            {InfoIconV2()}
          </div>
        </OverlayTrigger>
      </ToolTipNew>)}

  render() {
    const { healthWellbeingCardData, wellnessWins, addressableHealthRisk, healthImprovementOpportunities, companyName } = this.props;
    if (isUndefined(healthWellbeingCardData) || isEmpty(healthWellbeingCardData) || isUndefined(healthWellbeingCardData['health_wellness_assessment'])) {
      return <Waiting />
    }
    let wins="We display wins in health where the average score exceeds Mark of 75%.";
    let mid="We showcase areas for enhancement in health where the average score falls within the range of 40% to 75%.";
    let risk="We present health risks that can be addressed, with average scores ranging from 0% to 41%.";
    return (
      <Wrapper>
        <div className="firstContainer" style={{ width: '48.2%' }}>
          <div className="topCardWrapper">
            {this.renderFourCards()}</div>
        </div>
        <div className="secondContainer" style={{ width: "51.8%", paddingLeft: '24px', display: 'flex' }}>
          <div className="scoreCard" style={{ width: '624px', height: '384px' }}>
            <ScoreCard padding={"0px"}>
              <div className="heading" style={{ padding: '12px', border: 'unset' }}>
                Health and Wellness Score Distribution
              </div>
              <div className="body" style={{ padding: '0px 12px 0px 12px' }}>
                {this.healthWellnessScoreCard()}
              </div>
            </ScoreCard>
          </div>
        </div>
        <div className="winsRisksCard">
          <div className="winsCard">
            <ScoreCard>
              <div className="winsHeading" style={{background:"#FFF"}}>
                <div className='icon'>
                  <img src={ImageUrl+"/"+"images/AdminReports/win.png"}/>
                </div>
                Top 5 Wellness Wins
                {this.infoDetails(wins)}
              </div>
              <div className="winsBody">
                {wellnessWins && wellnessWins.length > 0? wellnessWins.map((data, index) => (
                  <WellnessWinsCard key={index} border={1} color={"rgba(159, 201, 137, 1)"}>
                    <div className="name">{data.tag}</div>
                    <div className="percentageCard">{data.average}%</div>
                  </WellnessWinsCard>)):
                  <div className='noData'>
                    <div className='image'>
                      <img src={ImageUrl+"/"+"images/AdminReports/risks.png"}/>
                    </div>
                    <div className='empty'>
                      Keep it going {companyName}! 
                      {" There's lots of room for improvement "}
                      until we can pinpoint your top wellness wins.
                    </div>
                  </div>                 
                }
              </div>
            </ScoreCard>
          </div>
          <div className="winsCard">
            <ScoreCard>
              <div className="winsHeading" style={{background:"#FFF"}}>
                <div className='icon'>
                  <img src={ImageUrl+"/"+"images/AdminReports/mid.png"}/>
                </div>
                Top 5 Improvement Opportunities
                {this.infoDetails(mid)}
              </div>
              <div className="winsBody">
                {healthImprovementOpportunities && healthImprovementOpportunities.length > 0 ? healthImprovementOpportunities.map((data, index) => (
                  <WellnessWinsCard key={index} border={1}  color={"rgba(0, 92, 135, 1)"} background={"#F1C97726"}>
                    <div className="name">{data.tag}</div>
                    <div className="percentageCard">{data.average}%</div>
                  </WellnessWinsCard>)):
                  <div className='noData'>
                    <div className='image'>
                      <img src={ImageUrl+"/"+"images/AdminReports/oppertunity.png"}/>
                    </div>
                    <div className='empty'>
                      Keep moving forward {companyName}!
                      {" There are no immediate improvement "}opportunities for you but check back soon.
                    </div>
                  </div>   
                }
              </div>
            </ScoreCard>
          </div>
          <div className="winsCard">
            <ScoreCard>
              <div className="winsHeading" style={{background:"#FFF"}}>
                <div className='icon'>
                  <img src={ImageUrl+"/"+"images/AdminReports/risk.svg"}/>
                </div>
                Top 5 Addressable Health Risks
                {this.infoDetails(risk)}
              </div>
              <div className="winsBody">
                {addressableHealthRisk && addressableHealthRisk.length > 0 ? addressableHealthRisk.map((data, index) => (
                  <WellnessWinsCard key={index} border={1} color={"rgba(0, 92, 135, 1)"} background={"#F4AAA926"}>
                    <div className="name">{data.tag}</div>
                    <div className="percentageCard">{data.average}%</div>
                  </WellnessWinsCard>)):
                  <div className='noData'>
                    <div className='image'>
                      <img src={ImageUrl+"/"+"images/AdminReports/wins.png"}/>
                    </div>
                    <div className='empty'>
                      Great job {companyName}!
                      {" You don't have any immediate "} addressable health risks at this time.
                    </div>
                  </div> 
                }
              </div>
            </ScoreCard>
          </div>
        </div>
      </Wrapper>
    );
  }
}

HealthTab.propTypes = {
  history: PropTypes.object,
  assesmentDetails: PropTypes.array,
  healthWellbeingCardData: PropTypes.array,
  wellnessWins: PropTypes.array,
  addressableHealthRisk: PropTypes.array,
  healthImprovementOpportunities: PropTypes.array,
  companyName: PropTypes.string
}

export default HealthTab;